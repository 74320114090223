const skillList = [
	// FRONT END
	{
		name: "HTML5",
		icon: "html5.svg"
	},
	{
		name: "CSS3",
		icon: "css3.svg"
	},
	// { name: "SASS/SCSS", icon: "sass.svg" },
	// { name: "gulp", icon: "nodejs.svg" },
	{
		name: "JavaScript",
		icon: "javascript.svg"
	},
	{
		name: "ReactJS",
		icon: "react.svg"
	},
	{
		name: "Redux",
		icon: "redux.svg"
	},
	// { name: "React Native", icon: "react.svg" },
	// { name: "Angular", icon: "angular.svg" },
	// { name: "VueJS", icon: "vue.svg" },
	// { name: "Php", icon: "php.svg" },
	// { name: "TypeScript", icon: "typescript.svg" },
	// { name: "PostScript", icon: "postscript2.svg" },
	// BACK END
	{
		name: "Python",
		icon: "python-icon.svg"
	},
	{
		name: "Ruby",
		icon: "ruby.svg"
	},
	{
		name: "Rails",
		icon: "rails.svg"
	},
	{
		name: "PostgreSQL",
		icon: "postgres.svg"
	},
	// { name: "SQL", icon: "postgres.svg" },
	{
		name: "Node.js",
		icon: "nodejs.svg"
	},
	{
		name: "TypeScript",
		icon: "typescript.svg"
	},
	// DEV OPS
	{
		name: "npm",
		icon: "npm-logo-red.svg"
	},
	{
		name: "GIT",
		icon: "git.svg"
	},
	{ 	
		name: "Docker",
		icon: "docker.svg"
	},
	// { name: "yarn", icon: "nodejs.svg" },
	// { name: "brew", icon: "git.svg" },
	// { name: "Amazon Web Services", icon: "aws.svg" },
	// { name: "Google Cloud Platform", icon: "Google_Cloud_Icon.svg" },
	{
		name: "Heroku",
		icon: "heroku.svg"
	},
	{
		name: "Netlify",
		icon: "Netlify-Logo.svg"
	},
	// SOFTWARE
	{
		name: "Creative Cloud",
		icon: "creativeCloud.svg"
	},
	{
		name: "Photoshop",
		icon: "photoshop-cc.svg"
	},
	{
		name: "Illustrator",
		icon: "adobe-illustrator-cc.svg"
	}
]

export default skillList
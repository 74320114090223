import React, {Component} from 'react'
import { Menu, Sidebar, Responsive, Icon, Image, Segment, Header, Button, MenuHeader } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';


class Nav extends Component{
  state = {
      activeItem: null,
      sideBarVisible: false,
      homeHover: false,
      aboutHover: false,
      projectHover: false,
      resumeHover: false,
      blogsHover: false,
      contactHover: false,
      gameHover: false
  }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleAboutClick = (e, {name}) => swal({
    icon: "info",
    text: "About Coming Soon !"
  });


render(){
  const { activeItem, sideBarVisible } = this.state
  return(
    <div>
    <Responsive {...Responsive.onlyComputer}>
      <Menu className='menu navbar' >
        <Menu.Item name='Home'
        as={Link} //Needed to make the menu item a link to change url
        to="/"
        active={activeItem === 'Home'}
        onClick={this.handleItemClick}
        onMouseOver={() => this.setState({homeHover: true})}
        onMouseLeave={() => this.setState({homeHover: false})}>
        {this.state.homeHover ? "Home" : <Icon name='home' size="large"/>}
        </Menu.Item>

        <Menu.Item name='About'
        as={Link}
        to="/"
        active={activeItem === 'About'}
        onClick={this.handleAboutClick}
        onMouseOver={() => this.setState({aboutHover: true})}
        onMouseLeave={() => this.setState({aboutHover: false})}>
        {this.state.aboutHover ? "About" : <Icon name='info' size="large"/>}
        </Menu.Item>

        <Menu.Item name='Projects'
        as={Link}
        to="/projects"
        active={activeItem === 'Projects'}
        onClick={this.handleItemClick}
        onMouseOver={() => this.setState({projectHover: true})}
        onMouseLeave={() => this.setState({projectHover: false})}>
        {this.state.projectHover ? "Projects" : <Icon name='archive' size="large"/>}
        </Menu.Item>

        <Menu.Item name='Resume'
        as={Link}
        to="/resume"
        active={activeItem === 'Resume'}
        onClick={this.handleItemClick}
        onMouseOver={() => this.setState({resumeHover: true})}
        onMouseLeave={() => this.setState({resumeHover: false})}>
        {this.state.resumeHover ? "Resume" : <Icon name='file alternate' size="large"/>}
        </Menu.Item>

        <Menu.Item name='Blogs'
        as={Link}
        to="/blogs"
        active={activeItem === 'Blog'}
        onClick={this.handleItemClick}
        onMouseOver={() => this.setState({blogsHover: true})}
        onMouseLeave={() => this.setState({blogsHover: false})}>
        {this.state.blogsHover ? "Blogs" : <Icon name='medium' size="large"/>}
        </Menu.Item>

        <Menu.Item name='Contact'
        as={Link}
        to="/contact"
        active={activeItem === 'Contact'}
        onClick={this.handleItemClick}
        onMouseOver={() => this.setState({contactHover: true})}
        onMouseLeave={() => this.setState({contactHover: false})}>
        {this.state.contactHover ? "Contact" : <Icon name='envelope' size="large"/>}
        </Menu.Item>

        <Menu.Item name='Game'
        as={Link}
        to="/game"
        active={activeItem === 'Game'}
        onClick={this.handleItemClick}
        onMouseOver={() => this.setState({gameHover: true})}
        onMouseLeave={() => this.setState({gameHover: false})}>
        {this.state.gameHover ? "Game" : <Icon name='game' size="large"/>}
        </Menu.Item>
      </Menu>
    </Responsive>
                              {/* Tablet */}
    {/* --------------------------------------------------------- */}
    <Responsive {...Responsive.onlyTablet}>
      <Menu className='menu navbar' >
          <Menu.Item name='Home'
          as={Link}
          to="/"
          active={activeItem === 'Home'}
          onClick={this.handleItemClick}
          />

          <Menu.Item name='About'
          as={Link}
          to="/"
          active={activeItem === 'About'}
          onClick={this.handleAboutClick}/>

          <Menu.Item name='Projects'
          as={Link}
          to="/projects"
          active={activeItem === 'Projects'}
          onClick={this.handleItemClick}/>

          <Menu.Item name='Resume'
          as={Link}
          to="/resume"
          active={activeItem === 'Resume'}
          onClick={this.handleItemClick}/>

          <Menu.Item name='Blogs'
          as={Link}
          to="/blogs"
          active={activeItem === 'Blog'}
          onClick={this.handleItemClick}/>

          <Menu.Item name='Contact'
          as={Link}
          to="/contact"
          active={activeItem === 'Contact'}
          onClick={this.handleItemClick}/>

          <Menu.Item name='Game'
          as={Link}
          to="/game"
          active={activeItem === 'Game'}
          onClick={this.handleItemClick}/>
      </Menu>
    </Responsive>
                      {/* Mobile Menu  */}
    {/* --------------------------------------------------- */}
    <Responsive {...Responsive.onlyMobile}>
      <Menu>
        <Menu.Item as={Link} to='/'>
          <Image src={'chibuguzo.jpg'} size="mini"/>
        </Menu.Item>

        <Sidebar
          fluid
          inverted
          as={Menu}
          animation='overlay'
          icon='labeled'
          direction="right"
          onHide={() => this.setState({sideBarVisible: false})}
          vertical
          visible={sideBarVisible}
          width='thin'
        >
          <Menu.Item
          onClick={() => this.setState({sideBarVisible: false})}
          as={Link}
          to="/"
          style={{color: "black"}} 
          >
            Home
          </Menu.Item>

          <Menu.Item
          onClick={() => this.setState({sideBarVisible: false})}
          as={Link}
          to="/"
          style={{color: "black"}} >
            About
          </Menu.Item>

          <Menu.Item
          onClick={() => this.setState({sideBarVisible: false})}
          as={Link}
          to="/projects"
          style={{color: "black"}}>
            Projects
          </Menu.Item>

          <Menu.Item
          onClick={() => this.setState({sideBarVisible: false})}
          as={Link}
          to="/resume"
          style={{color: "black"}}>
            Resume
          </Menu.Item>

          <Menu.Item
          onClick={() => this.setState({sideBarVisible: false})}
          as={Link}
          to="/blogs"
          style={{color: "black"}}>
            Blogs
          </Menu.Item>

          <Menu.Item
          onClick={() => this.setState({sideBarVisible: false})}
          as={Link}
          to="/contact"
          style={{color: "black"}}>
            Contact
          </Menu.Item>

          <Menu.Item
          onClick={() => this.setState({sideBarVisible: false})}
          as={Link}
          to="/game"
          style={{color: "black"}}>
            Game
          </Menu.Item>
        </Sidebar>
        <Menu.Item position="right">
        <Button className="burger-button" onClick={() => this.setState({sideBarVisible: true})}>
              <Icon color="black" name="bars" />
        </Button>
        </Menu.Item>
      </Menu>
    </Responsive>
    </div>
)}
}

export default Nav
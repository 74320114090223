import React from 'react'
import { Canvas } from 'react-canvas-js';
import { Image, Segment } from 'semantic-ui-react'
import TypeIt from 'typeit-react'

const sample1 = {
    "maxParticles": 100,
    "colors": [
      "#2E1D62",
      "#513D91",
      "#487EEF",
      "#11A887",
      "#fc5c65",
      "#fed330"
    ],
    "shapes": [
      "circle",
      "square"
    ],
    "size": 20,
    "minSpeed": 0.10,
    "maxSpeed": 1,
    "alpha": 0.7,
    "backgroundColor": "#FFFFF1"
  };
  

function Home() {
    return(
        <div>
            <div className="box"><Canvas className="canvas" options={sample1} /></div>
            <div className="box stack-top">
                <div className="home-info">
                    <div>
                    <br /><br /><br /><br /><br /><br /><br />
                    {/* <Image centered className="home-image" src={'Uzoma-Portrait.jpeg'} size="medium" circular/> */}
                    <br /><br /><br />
                    <TypeIt>
                        {/* <strong className="home-name">Hi, I'm Uzoma Ariguzo</strong>
                        <br /><br />
                        <p className="home-description">FullStack Web Developer, Software Engineer</p> */}
                        <h1 className="home-description">
                            Site Currently Under Renovation.
                        </h1>
                        <br />
                        <h1 className="home-description">
                            Check Back Soon!
                        </h1>
                        <br />
                        <h1 className="home-description">
                            - Uzo
                        </h1>
                    </TypeIt>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home

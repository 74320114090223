import React, {useState, useEffect} from 'react'
import SingleCard from './SingleCard.js'
import { Canvas } from 'react-canvas-js';
import Confetti from "react-confetti";
import { Advertisement } from 'semantic-ui-react';

const sample1 = {
  "maxParticles": 10,
  "colors": [
    "#2E1D62",
    "#513D91",
    "#487EEF",
    "#11A887",
    "#fc5c65",
    "#fed330"
  ],
  "shapes": [
    "square"
  ],
  "size": 20,
  "minSpeed": 0.10,
  "maxSpeed": 2,
  "alpha": 0.7,
  "backgroundColor": "black"
};

const cardImages = [
  { "src": "/img/jango.jpeg", matched: false },
  { "src": "/img/lando.jpeg", matched: false },
  { "src": "/img/vadar.jpeg", matched: false },
  { "src": "/img/jar-jar.jpeg", matched: false },
  { "src": "/img/padme.jpeg", matched: false },
  { "src": "/img/windu.jpeg", matched: false }
]

function Game() { //Grid like interface
  const [cards, setCards] = useState([])
  const [turns, setTurns] = useState(0)
  const [choiceOne, setChoiceOne] = useState(null)
  const [choiceTwo, setChoiceTwo] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)

  const shuffleCards = () => {
    const shuffledCards = [...cardImages, ...cardImages] // Spread the cards twice for duplicates
      .sort(() => Math.random() - 0.5)
      .map(card => ({
        ...card, id: Math.random()
    }))

    setCards(shuffledCards)
    setTurns(0)
    setHeight(0)
    setWidth(0)
  }

  const handleChoice = (card) => {
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card)
  }

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true)
      if (choiceOne.src === choiceTwo.src){
        setCards(prevCards => { // Setting the cards as matched if we have a match!
          return prevCards.map(card => {
            if (card.src === choiceOne.src) {
              return {...card, matched: true}
            } else {
              return card
            }
          })
        })
        resetTurn()
      } else {
        setTimeout(() => resetTurn(), 1000)
      }
    }

    const pluck = property => element => element[property]
    const trueArray = cards.map(pluck('matched'))

    if (!trueArray.includes(false) && trueArray.length !== 0) {
      setHeight(window.outerHeight)
      setWidth(window.outerWidth)
    }
  
  }, [choiceOne, choiceTwo])

  const resetTurn = () => {
    setChoiceOne(null)
    setChoiceTwo(null)
    setTurns(prevTurn => prevTurn + 1)
    setDisabled(false)
  }

  return (
    <div>
      <div className="box">
        <Canvas className="canvas" options={sample1} />
        <Confetti numberOfPieces={150} width={width} height={height} />
      </div>
        <div className="box stack-top">
        <div className="game-app">
          <h1 className="game-title_text">Star Wars Character Match</h1>
          <div className="game-button">
            <button onClick={shuffleCards}>New Game</button>
          </div>
          <br />
          <p className="game-turn_text"> Turns: {turns}</p>

          <div className="card-grid">
            {cards.map(card => (
              <SingleCard 
                key={card.id} 
                card={card} 
                choice={handleChoice} 
                flipped={card === choiceOne || card === choiceTwo}
                disabled={disabled}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Game;

import React from 'react'
import "./SingleCard.css"

export default function SingleCard({card, choice, flipped, disabled}){

    const isFlipped = flipped || card.matched
    const {src, id} = card
    const handleClick = () => {
        if (!disabled) {
            choice(card)
        }
    }
    
    return(
        <div className="card" key={id}>
            <div className={isFlipped ? "flipped" : ""}>
                <img src={src} className="front" alt="front card"/>
                <img src="./img/poster.jpeg" className="back" onClick={handleClick} alt="cover"/>
            </div>
        </div>
    )
}
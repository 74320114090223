const resume = {
	name: "Nduka-Eze Uzoma Ariguzo",
	email: "u.ariguzo.ua@gmail.com",
	telephone: "301-357-5385",
	website: "u.ariguzo.ua.com",
	technicalSkills: {
		technology: "vRuby, Rails, Javascript, React, Redux,TypeScript, Angular, Jquery, HTML, CSS, Docker, PostgreSQL, mySQL, Git Workflow, Gitlabs, JSON, Heroku/Netlify, Jest, Mocha, Rspec, Microsoft Suite, Slack, Discord, Jira, WCAG Accessibility"
	},
	experience: [
		{
			company: "EAB",
			location: "Washington D.C",
			positions: [
				{
					role: "Software Engineer",
					fullTerm: "11/2020 - Present",
					bullets: [
                        "Actively works to triage and fix bugs that are reported by partners in a timely manner.",
						"Builds out features using full stack development (Ruby on Rails, MYSQL, etc.) to enhance Navigate SSMS.",
					 	"Takes ownership of Web Accessibility standards on the Strategic Care team to keep in line with WCAG compliance."
                    ]
				}
			]
		},
		{
			company: "WeeSeeGhosts",
			location: "Bowie, MD",
			positions: [
				{
					role: "Frontend Software Engineer",
					fullTerm: "11/2020 - Present",
					bullets: [
                        "Actively works on Web3 brand creating the world's first omnichain gameverse.",
                        "Extensively uses React/Redux for state management for our web application.",
                        'Worked on Responsive Designing and Accessibility exceptions for tablets and mobile devices.'
                    ]
				}
			]
		},
		{
			company: "MARE",
			location: "Bowie, MD",
			positions: [
				{
					role: "Marketing Staff / Business Analyst",
					fullTerm: "11/2020 - Present",
					bullets: [
                        'Writes and designs marketing emails(product and content promotions) using MailChimp.',
                        'Markets and communicates the brand across multiple social media platforms.',
                        'Implements new ideas to ensure overall success using brand imaging.'
                    ]
				}
			]
		},
		{
			company: "University of Maryland Lutheran Campus Ministry",
			location: "College Park, MD",
			positions: [
				{
					role: "IT Specialist",
					fullTerm: "11/2020 - Present",
					bullets: [
						'Worked as overall IT support for ministry needs.',
                        'Planned for, maintained, and created a web presence for the ministry.',
						'Organized and spearheaded community outreach programs (globally and locally).'
					]
				}
			]
		},
		{
			company: "Young Adults and Global Missons Program",
			location:'Victoria, Australia',			         
			positions: [
				{
					role: "Program Manager",
					fullTerm: "11/2020 - Present",
					bullets: [
						'Taught technology workshops using Windows/MAC OS.',
                        'Developed visuals for community events using HTML/CSS.'
					]
				}
			]
		}
	],
	education: [
		{
			school: "University of Maryland College Park",
			location: "College Park, MD",
			graduationYear: 2017,
			program: "Bachelor of Arts (BA) - Psychology / Bachelors of Science (BS) - Family Science",
		},
		{
			school: "Flatiron School",
			location: "Washington, DC",
			graduationYear: 2020,
			program: "Full Stack Software Engineering Ruby on Rails and JavaScript immersive program",
		}
	]
}

export default resume
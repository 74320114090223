import React from 'react'
import RESUME from '../data/resume.js'
import styled from 'styled-components'
import Skills from './Skills.js'
// import resumeDownload from '../utilities/Resume.pdf'
import Experience from '../components/Experience.js'
import Education from '../components/Education.js'
import { device } from '../device';
import TypeIt from 'typeit-react'
import { Button } from 'semantic-ui-react'

const ResumeContainer = styled.div`
	width: 47%;
	margin: auto;
	@media only screen and ${device.laptop} { 
		width: 50%;
	}
	@media only screen and ${device.tablet} { 
		width: 70%;
	}
	@media only screen and ${device.mobileL} {
		width: 90%;
	}
`
  
function Resume(props)  {
	// From Resume JSON file
	let resume = RESUME
	// Loop through resume education by years
	let education = resume.education.sort((a,b) => a.graduationYear > b.graduationYear ? -1 : 1)

	return (
		<div className="resume">
            <br /><br />
            <div className="resume-box resume-stack-top">
			<br />
			<ResumeContainer>
				<br /><br /><br />
					<TypeIt>
						<strong className="title-name">Resume</strong>
					</TypeIt>
				<br />
				{/* <a href={resumeDownload} download><Button>Download Resume</Button></a> */}
				<h1 style={{textAlign: "center"}} >Experience</h1>
				{ resume.experience.map(job => <Experience job={ job } key={ job.company }/> ) }
				<h1 style={{textAlign: "center"}}>Training</h1>
				{ education.map(school => <Education school={ school } key={ school.school }/>) }
				<Skills />
			</ResumeContainer>
		</div>
		</div>
	)
}

export default Resume